import { FC } from 'react'
import { Box, Flex } from '@/components/atoms/Grid'
import styled, { withTheme } from 'styled-components'
import { transparentize } from 'polished'
import { SlimBottomArrowIconDefault } from '../atoms/icons/SlimBottomArrowIconDefault'

export enum ArrowDirection {
  next,
  prev
}

type ArrowProps = {
  onClick: () => void
  iconWidth?: number
  iconHeight?: number
} & Rest

type HorizontalArrowProps = {
  arrowDirection: ArrowDirection
  fillBackground: boolean
} & ArrowProps

export const HorizontalArrow: FC<HorizontalArrowProps> = withTheme(
  ({ onClick, theme, iconWidth, iconHeight, arrowDirection, ...rest }) => {
    const props = {
      width: iconWidth ?? 24,
      height: iconHeight ?? 24,
      color: theme.colors.onBackground
    }
    return (
      <StyledHorizontalArrowWrapper onClick={onClick} {...rest}>
        <RotatableBox
          arrowDirection={arrowDirection}
          {...props}
          data-cy={`${
            arrowDirection === ArrowDirection.prev ? 'prev' : 'next'
          }Arrow`}
        />
      </StyledHorizontalArrowWrapper>
    )
  }
)

const StyledHorizontalArrowWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.fillBackground &&
    `background-color: ${transparentize(0.3, props.theme.colors.background)};`}
  &:hover {
    cursor: pointer;
  }
`

const RotatableBox = styled(SlimBottomArrowIconDefault)`
  ${(props) =>
    props.arrowDirection === ArrowDirection.prev
      ? 'transform: rotate(90deg)'
      : 'transform: rotate(270deg)'};
  overflow: hidden;
  height: 43px;
  width: 43px;
`

type VerticalArrowProps = {
  arrowDirection: ArrowDirection
} & ArrowProps

export const VerticalArrow: FC<VerticalArrowProps> = withTheme(
  ({ arrowDirection, onClick, iconHeight, iconWidth, theme, ...rest }) => (
    <StyledVerticalArrowWrapper
      onClick={onClick}
      doRotate={arrowDirection === ArrowDirection.prev}
      {...rest}
    >
      <SlimBottomArrowIconDefault
        width={iconWidth ?? 24}
        height={iconHeight ?? 24}
        color={theme.colors.onBackground}
      />
    </StyledVerticalArrowWrapper>
  )
)

const StyledVerticalArrowWrapper = styled(Box)`
  justify-content: center;
  padding: 2px 0;
  border-radius: 2px;
  &:hover {
    cursor: pointer;
  }
  opacity: ${(props) => (props.isDisabled ? 0.2 : 0.6)};
  border: solid 1px ${(props) => props.theme.colors.onBackground};
  ${(props) => props.doRotate && 'transform: rotate(180deg)'}
`
