import { DependencyList, useEffect } from 'react'

const useTimer = (
  callback: () => void,
  timeout: number,
  dependencies?: DependencyList
): void => {
  useEffect(() => {
    const timeoutId = setTimeout(() => callback(), timeout)
    return () => clearTimeout(timeoutId)
  }, dependencies ?? [])
}

export default useTimer
